import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Heading1, Heading2, Heading3 } from './Project/Headings';
import ImageWrapper from './Project/ImageWrapper';
import Postcard from './Project/Postcard';
import Prose from './Project/Prose';
import VideoEmbed from './Project/VideoEmbed';


const StyledHero = styled.div`
  font-family: "Roboto", sans-serif;
  
  margin-bottom: 160px;
`;

const Title = styled.h1`
  text-align: right;
  
  font-size: 48px;
  font-weight: bold;
  
  color: ${props => props.theme.black};
`;

const SubTitle = styled.h3`
  text-align: right;
  
  font-size: 30px;
  font-weight: normal;
  
  color: ${props => props.theme.darkGrey};
`;

const Hits = styled.div`
  display: flex;
  justify-content: flex-end;
  
  cursor: ${props => props.cursor};
`;
const Hit = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  
  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.primary};
    padding-right: 0.8em;
    margin-right: 0.8em;
  }
  
  color: ${props => props.theme.primary};
`;


const noop = () => {};


const COMPONENT_MAP = {
    Title: ({ text }) => <Title>{text}</Title>,
    Subtitle: ({ text }) => <SubTitle>{text}</SubTitle>,
    Services: ({ text, scrollToServices }) => (
        <Hits onClick={scrollToServices} cursor={scrollToServices === noop ? 'auto' : 'pointer'}>
            {text.split(' | ').map(hit => (
                <Hit>{hit}</Hit>
            ))}
        </Hits>
    ),
};


const Hero = ({ scrollToServices }) => {
    const { HeroData } = useStaticQuery(graphql`
        query {
            HeroData: allAirtable(filter: {table: {eq: "Hero"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        component
                        content
                    }
                }
            }
        }
    `);

    const heroContent = HeroData.nodes;

    return (
        <StyledHero>
            {heroContent.map(({ id, data }) => {
                const content = {
                    text: data.content,
                };

                const Component = COMPONENT_MAP[data.component];

                if (!Component) {
                    console.warn(`${data.component} doesn't match a component!`);
                    return null;
                }

                return (
                    <Component {...content} scrollToServices={scrollToServices} />
                )
            })}
        </StyledHero>
    );
};

Hero.propTypes = {
    scrollToServices: PropTypes.func,
};

Hero.defaultProps = {
    scrollToServices: noop,
};

export default Hero;
