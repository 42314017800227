import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styled from 'styled-components';
import CaseStudy from './CaseStudy';


const StyledCaseStudies = styled.div``;


const CaseStudies = () => {
    const { projectData } = useStaticQuery(graphql`
        query {
            projectData: allAirtable(filter: {table: {eq: "Projects"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        popColour: Primary_colour__button_
                        title: Heading
                        name: Project_Name
                        desc: Homepage_blurb
                        slug
                        image: Lead_image {
                            localFiles {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const projects = projectData.nodes;

    return (
        <StyledCaseStudies>
            {projects.map((project, i) => (
                <CaseStudy
                    key={project.id}
                    orientation={i % 2 ? "right" : "left"}
                    {...project.data}
                />
            ))}
        </StyledCaseStudies>
    );
};

CaseStudies.propTypes = {};

CaseStudies.defaultProps = {};

export default CaseStudies;
