import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styled from 'styled-components';


const StyledCapabilities = styled.div`
    font-family: "Roboto", sans-serif;
`;

const Title = styled.h1`
    font-size: 40px;
    font-weight: normal;
    
    margin-bottom: 70px;
`;

const CapabilitiesContainer = styled.div`
    display: grid;
    
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 60px 7.5%;
    justify-content: space-between;
`;

const Group = styled.div`
    display: flex;
    flex-direction: column;
`;
const Heading = styled.div`
    font-size: 24px;
    font-weight: bold;
    
    margin-bottom: 24px;
`;
const Hit = styled.div`
    font-size: 16px;
`;


const Capabilities = () => {
    const { serviceData } = useStaticQuery(graphql`
        query {
            serviceData: allAirtable(filter: {table: {eq: "Services"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        title
                        content: Services_Content {
                            id
                            data {
                                text
                            }
                        }
                    }
                }
            }
        }
    `);

    const services = serviceData.nodes;

    return (
        <StyledCapabilities>
            <Title>Services</Title>

            <CapabilitiesContainer>
                {services.map(service => (
                    <Group key={service.id}>
                        <Heading>{service.data.title}</Heading>
                        {service.data.content.map(content => (
                            <Hit key={content.id}>{content.data.text}</Hit>
                        ))}
                    </Group>
                ))}
            </CapabilitiesContainer>
        </StyledCapabilities>
    );
};

Capabilities.propTypes = {};

Capabilities.defaultProps = {};

export default Capabilities;
