import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import styled from 'styled-components';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const StyledExperiment = styled.div`
    font-family: "Roboto", sans-serif;
    
    display: flex;
    flex-direction: column;
`;

const ImageWrapper = styled.div`
    margin-bottom: 32px;
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: bold;
    
    margin-bottom: 32px;
    
    color: black;
`;
const Blurb = styled.div`
    font-size: 20px;
    
    margin-bottom: 32px;
`;

const AnchorNoStyle = styled.a`
    text-decoration: none;
`;
const ExternalLink = ({ children, ...props }) =>
    <AnchorNoStyle {...props} target="_blank">{children}</AnchorNoStyle>
;

const LinkText = styled.span`
    font-size: 18px;
    font-weight: bold;
    
    text-decoration: none;
    color: ${props => props.theme.black};
    
    &:hover {
      color: ${props => props.theme.blackHover};
    }
`;

const ExternalLinkIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.primary};
    
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 1px;
`;


const Experiment = ({ image, title, blurb, siteUrl, articleUrl }) => (
    <StyledExperiment>
        <ImageWrapper>
            <ExternalLink href={siteUrl}>
                <Img
                    fluid={image.localFiles[0].childImageSharp.fluid}
                />
            </ExternalLink>
        </ImageWrapper>

        <ExternalLink href={siteUrl}>
            <Title>{title}</Title>
        </ExternalLink>
        <Blurb>{blurb}</Blurb>

        {siteUrl && (
            <ExternalLink href={siteUrl}>
                <LinkText>
                    Visit site
                    <ExternalLinkIcon icon={faExternalLinkAlt} />
                </LinkText>
            </ExternalLink>
        )}
        {articleUrl && (
            <ExternalLink href={articleUrl}>
                <LinkText>
                    Read on Medium
                    <ExternalLinkIcon icon={faExternalLinkAlt} />
                </LinkText>
            </ExternalLink>
        )}
    </StyledExperiment>
);

Experiment.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    blurb: PropTypes.string.isRequired,
    siteUrl: PropTypes.string,
    articleUrl: PropTypes.string,
};

Experiment.defaultProps = {};

export default Experiment;
