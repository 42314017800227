import { graphql } from 'gatsby';
import React, { useCallback } from 'react';

import styled from 'styled-components';
import Capabilities from '../components/Capabilities';
import CaseStudies from '../components/CaseStudies';
import Experiments from '../components/Experiments';
import Hero from '../components/Hero';
import Layout from '../components/Layout'
import Process from '../components/Process';

import Seo from '../components/Seo'
import WhyUs from '../components/WhyUs';

import { scrollTo } from '../util/scroll';


const Section = styled.div`
    margin-bottom: 200px;
`;


const IndexPage = () => {
    const scrollToServices = useCallback(
        () => {
            scrollTo('services');
        },
        [],
    );

    return (
        <Layout>
            <Seo title="Home" />

            <Hero scrollToServices={scrollToServices} />

            <Section id="projects">
                <CaseStudies />
            </Section>
            <Section id="process">
                <Process />
            </Section>
            <Section id="why-us">
                <WhyUs />
            </Section>
            <Section id="labs">
                <Experiments />
            </Section>
            <Section id="services">
                <Capabilities />
            </Section>
        </Layout>
    );
};

export default IndexPage;