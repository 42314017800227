import React from 'react';

import styled from 'styled-components';


const Title = styled.h1`
    font-size: 40px;
    font-weight: normal;
    
    margin-bottom: 70px;
`;

export default Title;
