import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styled from 'styled-components';
import Experiment from './Experiment';
import Title from './Section/Title';


const StyledExperiments = styled.div``;

const ExperimentsContainer = styled.div`
    display: grid;
    
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 60px 7.5%;
    justify-content: space-between;
`;


const Experiments = () => {
    const { experimentData } = useStaticQuery(graphql`
        query {
            experimentData: allAirtable(filter: {table: {eq: "Labs"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        title: Name
                        blurb: Description
                        siteUrl: Visit_site_link
                        articleUrl: Read_about_it_on_Medium_link
                        image: Lead_image {
                            localFiles {
                                childImageSharp {
                                    fluid(maxWidth: 300) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const experiments = experimentData.nodes;

    return (
        <StyledExperiments>
            <Title>Labs</Title>

            <ExperimentsContainer>
                {experiments.map(experiment =>
                    <Experiment
                        key={experiment.id}
                        {...experiment.data}
                    />
                )}
            </ExperimentsContainer>
        </StyledExperiments>
    );
};

Experiments.propTypes = {};

Experiments.defaultProps = {};

export default Experiments;
