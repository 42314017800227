import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';

import styled from 'styled-components';
import Title from './Section/Title';


const StyledProcess = styled.div`
    font-family: "Roboto", sans-serif;
`;

const Subtitle = styled.div`
    font-size: 30px;
    margin-bottom: 64px;
    line-height: normal;
`;

const Proses = styled.div`
    margin-bottom: 64px;
`;
const Prose = styled.p`
    font-size: 18px;
`;

const Hits = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-row-gap: 32px;
    
    margin-bottom: 64px;
`;
const Hit = styled.div`
    & h3 {
      font-size: 32px;
      margin-bottom: 10px;
    }
    
    & span {
      font-size: 24px;
    }
`;

const ImageContainer = styled.div`
    margin-bottom: 64px;
`;

const BigIfs = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));
    grid-row-gap: 80px;
    
    @media screen and (max-width: 410px) {
      grid-template-columns: 1fr;
    }
`;
const BigIf = styled.div`
    & h4 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 32px;
      
      & em {
        color: ${props => props.theme.primary};
        font-style: inherit;
      }
    }
    
    & ul {
      font-size: 16px;
    
      & li {
        margin-bottom: 0;
      }
    }
`;


const withEmphasis = text => text.replace(/\*(.+?)\*/g, '<em>$1</em>');


const Process = () => {
    const { titleData, proseData, hitData, imageData, bigIfData } = useStaticQuery(graphql`
        query {
            titleData: allAirtable(filter: {table: {eq: "Process"}, data: {component: {eq: "Title"}}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        headingContent: heading_content
                        textContent: text_content
                    }
                }
            }
            proseData: allAirtable(filter: {table: {eq: "Process"}, data: {component: {eq: "Prose"}}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        textContent: text_content
                    }
                }
            }
            hitData: allAirtable(filter: {table: {eq: "Process"}, data: {component: {eq: "Hit"}}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        headingContent: heading_content
                        textContent: text_content
                    }
                }
            }
            imageData: allAirtable(filter: {table: {eq: "Process"}, data: {component: {eq: "Image"}}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        imageContent: image_content {
                            localFiles {
                                childImageSharp {
                                    # TODO: why 1060?! 
                                    fluid(maxWidth: 1060) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            bigIfData: allAirtable(filter: {table: {eq: "Process"}, data: {component: {eq: "BigIf"}}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        headingContent: heading_content
                        textContent: text_content
                    }
                }
            }
        }
    `);

    const titleContent = titleData.nodes;
    const proseContent = proseData.nodes;
    const hitContent = hitData.nodes;
    const imageContent = imageData.nodes;
    const bigIfContent = bigIfData.nodes;

    return (
        <StyledProcess>
            {titleContent.map(({ id, data }) => (
                <Fragment key={id}>
                    <Title>{data.headingContent}</Title>
                    <Subtitle>{data.textContent}</Subtitle>
                </Fragment>
            ))}

            <Proses>
            {proseContent.map(({ id, data }) => (
                <Prose key={id}>
                    {data.textContent}
                </Prose>
            ))}
            </Proses>

            <Hits>
                {hitContent.map(({ id, data }) => (
                    <Hit key={id}>
                        <h3>{data.headingContent}</h3>
                        <span>{data.textContent}</span>
                    </Hit>
                ))}
            </Hits>

            {imageContent.map(({ id, data }) => (
                <ImageContainer key={id}>
                    <Img fluid={data.imageContent.localFiles[0].childImageSharp.fluid}/>
                </ImageContainer>
            ))}

            <BigIfs>
                {bigIfContent.map(({ id, data }) => (
                    <BigIf key={id}>
                        <h4
                            dangerouslySetInnerHTML={{
                                __html: withEmphasis(data.headingContent),
                            }}
                        />
                        <ul>
                            {data.textContent.split('\n').map((text, i) => (
                                <li key={i}>
                                    {text}
                                </li>
                            ))}
                        </ul>
                    </BigIf>
                ))}
            </BigIfs>
        </StyledProcess>
    );
};

Process.propTypes = {};

Process.defaultProps = {};

export default Process;