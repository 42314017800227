import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';


const StyledCaseStudy = styled.div`
    display: flex;
    justify-content: space-between;
    
    &:not(:last-child) {
      margin-bottom: 80px;
    }
    
    @media screen and (max-width: ${props => props.theme.bps.md}) {
      flex-direction: column;
    }
`;

const Side = styled.div`

`;

const TextSide = styled(Side)`
    padding-top: 40px;

    order: ${props => props.orientation === 'left' ? 1 : 2};
    
    text-align: ${props => props.orientation};
    
    width: 60%;
    
    @media screen and (max-width: ${props => props.theme.bps.md}) {
      order: 2;
      width: 100%;
    }
`;

const ImageSide = styled(Side)`
    order: ${props => props.orientation === 'left' ? 1 : 2};
    
    width: calc(50vw - 10%);
    margin-${props => props.orientation}: calc(-50vw + 50%);
    
    @media screen and (max-width: ${props => props.theme.bps.md}) {
      order: 1;
      width: 100%;
      margin: auto;
    }
`;

const Title = styled.h2`
    font-size: 32px;
    
    margin-bottom: 42px;
`;
const Name = styled.h3`
    font-size: 24px;
    font-weight: bold;
    
    color: ${props => props.color};
    
    margin-bottom: 22px;
`;
const Desc = styled.div`
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    
    margin-bottom: 22px;
`;

const GhostButton = styled(Link)`
  display: inline-block;
  
  border: 2px solid ${props => props.color};
  border-radius: 8px;
  
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  
  color: ${props => props.color};
  
  transition: 300ms linear all;
  
  &:hover {
    color: ${props => props.theme.white};
    background-color: ${props => props.color};
  }
  
  padding: 10px 22px;
  
  text-decoration: none;
`;


const CaseStudy = ({ orientation, popColour, title, name, desc, slug, image }) => (
    <StyledCaseStudy>
        <TextSide
            orientation={orientation}
        >
            <Title>{title}</Title>
            <Name color={popColour}>{name}</Name>
            <Desc>{desc}</Desc>
            <GhostButton
                target="_blank"
                to={`/projects/${slug}`}
                color={popColour}
            >
                Read the story
            </GhostButton>
        </TextSide>
        <ImageSide
            orientation={orientation === 'right' ? 'left' : 'right'}
        >
            <Img fluid={image.localFiles[0].childImageSharp.fluid} />
        </ImageSide>
    </StyledCaseStudy>
);

CaseStudy.propTypes = {
    orientation: PropTypes.oneOf(['left', 'right']),
    popColour: PropTypes.string,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
};

CaseStudy.defaultProps = {
    orientation: 'left',
    popColour: '#1abc9c',
};

export default CaseStudy;
