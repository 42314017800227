import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styled from 'styled-components';
import Title from './Section/Title';


const StyledWhyUs = styled.div`
    font-family: "Roboto", sans-serif;
`;

const Reasons = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-row-gap: 80px;
    justify-content: space-between;
    
    @media screen and (max-width: 400px) {
      grid-template-columns: 1fr;
    }
`;
const Reason = styled.div`
    max-width: 400px;
    
    & h6 {
      font-size: 18px;
      margin-bottom: 32px;
    }
    
    & p {
      font-size: 18px;
    }
`;

const WhyUs = () => {
    const { whyUsData } = useStaticQuery(graphql`
        query {
            whyUsData: allAirtable(filter: {table: {eq: "Why Us?"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        heading
                        text
                    }
                }
            }
        }
    `);

    const reasons = whyUsData.nodes;

    return (
        <StyledWhyUs>
            <Title>Why Shape?</Title>

            <Reasons>
                {reasons.map(({ id, data }) => (
                    <Reason key={id}>
                        <h6>{data.heading}</h6>
                        <p>{data.text}</p>
                    </Reason>
                ))}
            </Reasons>
        </StyledWhyUs>
    );
}

WhyUs.propTypes = {};

WhyUs.defaultProps = {};

export default WhyUs;